import React from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { VSpace } from '@/components/spacing';
import { LongInspirationTile } from '@/features/inspirations/long-inspiration-tile';
import { useInspirationsListing } from '@/features/inspirations/use-inspirations-listing';
import { Pagination } from '@/components/pagination';
import { ExperienceSpinner } from '@/components/experience-spinner';
import { breakpoints } from '@/components/responsive';
import { SearchInput } from '@/components/searchInput';

export const INSPIRATIONS_TAGS = [
  `*`,
  `Homepage`,
  `Products`,
  `Targets CR`,
  `Targets PSV`,
  `Targets AOV`,
  `Seen`,
];

export type InspirationOrigin =
  | `experiences`
  | `inspirations`
  | `publicInspirations`;

export interface InspirationsListingPageProps {
  origin: InspirationOrigin;
  pageSize?: number;
}

export function InspirationsListingPage({
  pageSize,
  origin,
}: InspirationsListingPageProps) {
  const {
    tag,
    setTag,
    search,
    setSearch,
    current,
    page,
    setPage,
    maxPages,
    total,
    loading,
  } = useInspirationsListing(pageSize);

  const onSearchTextChanged = (ev) => {
    const text = ev.target.value as string;
    setSearch(text);
    setTag(`*`);
  };

  const debouncedSearch = useDebouncedCallback(onSearchTextChanged, 300);

  const onPageChanged = (page: number) => {
    setPage(page - 1);
  };
  return (
    <Container id="inspirations">
      <h3>Inspiration Library</h3>
      <VSpace value={2.5} />
      <h4>
        Illustrated through real customer examples from the top Shopify brands
      </h4>
      <VSpace value={2} />
      <ProgressBar progress={current?.progress || 0}>
        <span className="progress" />
        <span className="tooltip">{current?.progress || 0}% Seen</span>
      </ProgressBar>
      <VSpace value={5.5} />
      <InspirationsControls>
        {INSPIRATIONS_TAGS.map((tagName) => (
          <InspirationsControlledTag
            key={tagName}
            tagName={tagName}
            selectedTag={tag}
            onSelected={setTag}
            total={total}
            loading={loading}
          />
        ))}
        <SearchInput defaultValue={search} grow onChange={debouncedSearch} />
      </InspirationsControls>
      <VSpace value={5} />
      <List>
        {!loading &&
          current?.inspirations?.map((inspiration) => (
            <LongInspirationTile
              id={inspiration.id}
              title={inspiration.title}
              image={inspiration.coverImage}
              description={inspiration.description}
              uplift={inspiration.uplift}
              url={inspiration.url}
              origin={origin}
            />
          ))}
        {!loading && !current?.inspirations?.length && (
          <>
            {(current?.progress || 0) === 100 && (
              <h4 className="no-results">
                You have finished viewing all inspirations. <br /> To see them
                again, you can access the <b>&apos;Seen&apos;</b> tab
              </h4>
            )}
            {(current?.progress || 0) < 100 && (
              <h4 className="no-results">
                There are no inspirations with the name you searched for...
              </h4>
            )}
          </>
        )}
        {loading && (
          <SpinnerWrapper>
            <ExperienceSpinner
              size={4}
              thickness={0.5}
              color="rgba(205,210,215)"
            />
          </SpinnerWrapper>
        )}
      </List>
      <VSpace value={5} />
      <Pagination
        value={page + 1}
        pageCount={maxPages}
        pageSize={pageSize || 6}
        total={total}
        loading={loading}
        onPageChanged={onPageChanged}
        label="inspirations"
      />
    </Container>
  );
}

export function InspirationsControlledTag({
  tagName,
  selectedTag,
  onSelected,
  total,
  loading,
}) {
  return (
    <Tag
      className={selectedTag === tagName ? `selected` : ``}
      onClick={() => onSelected(tagName)}
    >
      {tagName === `*` ? `All` : tagName}
      {` `}
      <span className={`count ${loading ? `loading` : ``}`}>
        ({total || 0})
      </span>
    </Tag>
  );
}

const Container = styled.div`
  font-family: 'Eesti', serif;
  display: flex;
  flex-direction: column;
  min-width: 70%;
  max-width: 70%;
  background: white;
  box-shadow: 0 1px 2px 0 rgb(0, 0, 0, 0.09), 0 2px 8px 0 rgb(7, 6, 6, 0.04);
  border-radius: 1.3rem;
  padding: 5rem 6rem;
  justify-content: center;
  align-content: center;

  h3 {
    margin: 0;
    font-size: 2.8rem;
    font-weight: bold;
  }

  h4 {
    margin: 0;
    font-size: 1.6rem;
    font-weight: lighter;
  }
`;

const ProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: 0.6rem;
  background-color: rgba(117, 117, 117, 0.16);
  border-radius: 1rem;
  margin-top: 4rem;
  max-width: 60%;
  align-self: center;

  span.progress {
    position: absolute;
    display: block;
    height: 100%;
    width: ${(props) => props.progress}%;
    background-color: rgba(116, 134, 157, 0.54);
    border-radius: 1rem;
  }

  span.tooltip {
    position: absolute;
    top: -4rem;
    left: calc(${(props) => props.progress}% - 4rem);
    background-color: #fff;
    padding: 0.8rem 1.2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    font-size: 1.2rem;
  }

  span.tooltip::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid #fff;
    top: 3rem;
    left: calc(50% - 0.4rem);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  }
`;

export const InspirationsControls = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-evenly;
  align-items: center;
  user-select: none;

  && > * {
    user-select: none;
  }

  input {
    font-family: 'Eesti', serif !important;
    font-weight: normal !important;
    font-size: 1.3rem !important;
    color: rgba(139, 146, 151);
    opacity: 0.74;
  }

  ${breakpoints.down(`md`)} {
    width: 100vw !important;
    max-width: 100vw;
    flex-wrap: nowrap;
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: center;
    gap: 5rem;
    padding: 0 3.5rem;

    && > * {
      font-size: 1.4rem;
      flex: 0 0 auto;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      justify-content: center;
      align-items: center;
    }
  }
`;

const Tag = styled.span`
  font-size: 1.3rem;
  font-weight: normal;
  color: rgba(139, 146, 151);
  opacity: 0.74;
  cursor: pointer;
  &&.selected {
    font-weight: bold;
    color: rgba(0, 0, 0);
    background: transparent;
    .count {
      opacity: 1;
      display: unset;
    }
  }

  .count {
    display: none;
    opacity: 0;
  }

  .count.loading {
    opacity: 0 !important;
  }

  &&:hover {
    opacity: 1;
  }
`;

const List = styled.div`
  min-height: 50rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  h4.no-results {
    font-size: 2rem;
    line-height: 1.7;
    margin-top: 20rem;
  }
`;

const SpinnerWrapper = styled.div`
  min-height: 50rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
