import React from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { GlobalStyles } from '@/styles/globalStyles';
import { Header } from '@/features/new-landing-page/components/header';
import { Footer } from '@/features/new-landing-page/components/footer';
import { PageWrapper } from '@/features/new-landing-page/pages/homepage';
import { VSpace } from '@/components/spacing';
import {
  INSPIRATIONS_TAGS,
  InspirationsControlledTag,
  InspirationsControls,
} from '@/features/inspirations/listing-page';
import { useInspirationsListing } from '@/features/inspirations/use-inspirations-listing';
import { LongInspirationTile } from '@/features/inspirations/long-inspiration-tile';
import { ExperienceSpinner } from '@/components/experience-spinner';
import { Pagination } from '@/components/pagination';
import { breakpoints } from '@/components/responsive';
import { DisruptSection } from '@/features/new-landing-page/sections/disrupt';
import { SearchInput } from '@/components/searchInput';

const PAGE_SIZE = 6;

export function PublicInspirationsListingPage() {
  const {
    tag,
    setTag,
    search,
    setSearch,
    current,
    page,
    setPage,
    maxPages,
    total,
    loading,
  } = useInspirationsListing(PAGE_SIZE, true);

  const onSearchTextChanged = (ev) => {
    const text = ev.target.value as string;
    setSearch(text);
    setTag(`*`);
  };

  const debouncedSearch = useDebouncedCallback(onSearchTextChanged, 300);

  const onPageChanged = (page: number) => {
    setPage(page - 1);
  };

  return (
    <PageWrapper>
      <GlobalStyles />
      <Header />
      <VSpace value={3} />
      <ContentWrapper>
        <h3>Inspirations Library</h3>
        <VSpace value={2} />
        <h4>
          Illustrated through real customer examples from the top-growing brands
        </h4>
        <VSpace value={4} />
        <InspirationsControls style={{ width: `100%` }}>
          {INSPIRATIONS_TAGS.map((tag) => (tag === `Seen` ? `Other` : tag)).map(
            (tagName) => (
              <InspirationsControlledTag
                key={tagName}
                tagName={tagName}
                selectedTag={tag}
                onSelected={setTag}
                total={total}
                loading={loading}
              />
            ),
          )}
          <SearchInput defaultValue={search} grow onChange={debouncedSearch} />
        </InspirationsControls>
        <VSpace value={4} />
        <List>
          {!loading &&
            current?.inspirations?.map((inspiration) => (
              <LongInspirationTile
                id={inspiration.id}
                title={inspiration.title}
                image={inspiration.coverImage}
                description={inspiration.description}
                uplift={inspiration.uplift}
                url={inspiration.url}
                origin="publicInspirations"
              />
            ))}
          {!loading && !current?.inspirations?.length && (
            <>
              {(current?.progress || 0) === 100 && (
                <h4 className="no-results">
                  You have finished viewing all inspirations. <br /> To see them
                  again, you can access the <b>&apos;Seen&apos;</b> tab
                </h4>
              )}
              {(current?.progress || 0) < 100 && (
                <h4 className="no-results">
                  There are no inspirations with the name you searched for...
                </h4>
              )}
            </>
          )}
          {loading && (
            <SpinnerWrapper>
              <ExperienceSpinner
                size={4}
                thickness={0.5}
                color="rgba(205,210,215)"
              />
            </SpinnerWrapper>
          )}
        </List>
        <VSpace value={5} />
        <Pagination
          value={page + 1}
          pageCount={maxPages}
          pageSize={PAGE_SIZE || 6}
          total={total}
          loading={loading}
          onPageChanged={onPageChanged}
          label="inspirations"
        />
        <VSpace value={8} />
      </ContentWrapper>
      <DisruptSection />
      <Footer />
    </PageWrapper>
  );
}

const ContentWrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  gap: 0rem;

  min-width: 70%;
  max-width: 70%;

  margin: 0 auto;

  font-family: Eesti, sans-serif;

  h3 {
    margin: 0;
    font-size: 3.8rem;
    letter-spacing: -0.8px;
    font-weight: 700;
  }

  h4 {
    margin: 0;
    font-size: 1.8rem;
    letter-spacing: -0.8px;
    font-weight: 300;
  }

  .long-inspirations-tile {
    background: white;
  }

  ${breakpoints.down(`md`)} {
    min-width: 85%;
    max-width: 85%;

    h3 {
      font-size: 3rem;
      text-align: center !important;
    }

    h4 {
      font-size: 1.7rem;
      font-weight: 500;
      text-align: center !important;
      line-height: 1.8;
    }
  }
`;

const List = styled.div`
  min-height: 50rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  h4.no-results {
    font-size: 2rem;
    line-height: 1.7;
    margin-top: 20rem;
  }

  ${breakpoints.down(`md`)} {
    max-width: 90%;
    gap: 4rem;
  }
`;

const SpinnerWrapper = styled.div`
  min-height: 50rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
